
import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
import Api from '@/api/index'
import Utils from '@/utils'
import empowerStep from '@/components/step-message/step-message.vue'

@Options({
  components: { empowerStep }
})
export default class ChangeStep extends SuperVue {
  created() {
    const id = this.$route.query.id
    this.getJudgeOptions(Number(id))
  }
  getJudgeOptions(id:number) {
    Api.http_outcome_change_get_judge_options({ id: id }).then(res => {
      if (res.code === '200') {
        this.JudgeOpinions = [];
        this.status = res.data.audit_status;
        res.data.judge_opinions.forEach(element => {
          const temp:any = {}
          if (Array.isArray(element)) {
            temp.duration = element[0].duration
            temp.sign_type = element[0].sign_type
            temp.current_node = element[0].current_node
            temp.title = this.allNode.get(element[0].current_node)
            temp.alltime = ''
            temp.user = []
          }
          element.forEach(element1 => {
            const time:string = Utils.diffTime(element1.created_at, element1.updated_at)
            temp.user.push({ name: element1.name, dept: element1.dept, status: element1.status, created_at: element1.created_at, updated_at: Utils.formatTime(element1.updated_at), time: time, reason: element1.reason })
            temp.alltime = time
          });
          this.JudgeOpinions.push(temp)
        });
        console.log(this.JudgeOpinions)
      }
    })
  }
  status:number=0
  JudgeOpinions:any[]=[]
  allNode = new Map([
    [1, '成员审核确认'],
    [4, '部门审核确认'],
    [6, '产研院审核确认'],
    [8, '权属委审核确认'],
    [10, '公示审核确认'],
    [12, '盖章确认'],
    [15, '资产公司审核确认'],
    [17, '权属委审核确认'],
    [20, '异常审议确认'],
    [22, '异常审议复审确认']
  ])
}
