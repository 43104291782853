
import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
import Utils from '@/utils'
import { number } from 'echarts'

@Options({
  components: {},
  watch: {
    options(newVal) {
      this.active = this.options.length - 1
    }

  },
  props: {
    options: [],
    curStatus: 0,
    failStatus: 0,
    passStatus: 0
  }
})
export default class StepMessage extends SuperVue {
  active: number = 0
  curStatus!:number
  failStatus!:number
  passStatus!:number
  options!:[]
  // message = [
  //   {
  //     duration: '2h',
  //     sign_type: 1,
  //     title: '转化申请相关人员确认',
  //     user: [
  //       {
  //         name: '李威',
  //         dept: '财务部/财务一组',
  //         status: 1,
  //         time: '03/12  17:23',
  //         image: 'https://picsum.photos/60/60'
  //       },
  //       {
  //         name: '李威',
  //         dept: '财务部/财务一组',
  //         status: 1,
  //         time: '03/12  17:23',
  //         image: 'https://picsum.photos/60/60'
  //       },
  //       {
  //         name: '李威',
  //         dept: '财务部/财务一组',
  //         status: 1,
  //         time: '03/12  17:23',
  //         image: 'https://picsum.photos/60/60'
  //       },
  //       {
  //         name: '李威',
  //         dept: '财务部/财务一组',
  //         status: 1,
  //         time: '03/12  17:23',
  //         image: 'https://picsum.photos/60/60'
  //       },
  //       {
  //         name: '李威',
  //         dept: '财务部/财务一组',
  //         status: 1,
  //         time: '03/12  17:23',
  //         image: 'https://picsum.photos/60/60'
  //       }
  //     ]
  //   }
  // ]
  status = new Map([
    [1, '待评审'],
    [2, '通过'],
    [3, '不通过']
  ])
  sign_type = new Map([
    [1, '或签'],
    [2, '并签']
  ])
  sign_type_class = new Map([
    [1, 'or'],
    [2, 'and']
  ])
  getHeight(len: number | undefined): string {
    if (!len) len = 3 // 0或undefined处理
    return Math.ceil(len / 3) * 100 + 30 + 'px'
  }
  get getLastStatus():string {
    console.log('xxx', this.curStatus, this.passStatus)
    if (this.curStatus === this.passStatus) {
      this.active = this.options.length
      return '完成';

    }
    if (this.curStatus === this.failStatus) {
      return '审核不通过';
    }
    return '审核中';
  }

  isFinish(stepIndex:number):boolean {
    const data:any = this.options[stepIndex]
    console.log(data)
    if (data.sign_type === 1) { // 或签
      return data.user.some(item => item.status === 2)
    }
    if (data.sign_type === 2) { // 并签
      return data.user.every(item => item.status === 2)
    }
    return true
  }
}
